<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg"
    v-if="preferences && preferences.length"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="about-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_preferences-category-help-online',
          query: { slug: helpSlug },
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_preferences' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ preferences[0].preference_category.name }}
        </div>
        <!-- For custom categories -->
        <template
          v-slot:right
          v-if="customPreferenceCategories.includes(Number(routeParam))"
        >
          <button @click="setData()">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="(preference, i) in preferences.filter(
          (pref) =>
            !customPreferenceCategories.includes(pref.preference_category_id)
        )"
        :key="i"
      >
        <router-link
          :to="{
            name: 'r_set-preference',
            params: { preferenceId: preference.id },
          }"
          class="clebex-item-content-wrapper"
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              preference.name
            }}</label>
            <p
              class="text-label ellipsis"
              v-if="
                (preference.is_automatic &&
                  preference.preference_values.length) ||
                  (!preference.is_automatic &&
                    preference.preference_user_values.length)
              "
            >
              <span
                v-for="(preferenceValue, index) in preference.is_automatic
                  ? preference.preference_values
                  : preference.preference_user_values"
                :key="index"
              >
                {{
                  displayLabelName(
                    "preferences",
                    "preference-category",
                    preferenceValue.label
                      ? preferenceValue.label
                      : preference.preference === "DEFAULT_SLOT"
                      ? preferenceValueLabels.DEFAULT_SLOTS[
                          preferenceValue.value
                        ]
                      : preferenceValue.value
                  )
                }}
                {{
                  preference.preference === "TIME_FORMAT"
                    ? preferenceValue.value.charAt(0) === "H"
                      ? "(24h)"
                      : "(AM/PM)"
                    : ""
                }}</span
              >
            </p>
            <p class="text-label ellipsis" v-else>
              <span>
                {{
                  displayLabelName(
                    "preferences",
                    "preference-category",
                    "not-selected"
                  )
                }}</span
              >
            </p>
          </div>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </router-link>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="(preference, i) in preferences.filter(
          (item) => item.preference_category_id === preferenceCategoryIds.menu
        )"
        :key="i"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  type="checkbox"
                  v-model="menu"
                  :id="preference.id"
                  :value="preference.id"
                  :name="preference.id"
                  :selected="menu.includes(preference.id)"
                />
                <label :for="preference.id"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ preference.name }}
            </dd>
          </dl>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="(preference, i) in preferences.filter(
          (item) =>
            item.preference_category_id ===
            preferenceCategoryIds.pushNotifications
        )"
        :key="i"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div
                class="checkbox slide"
                :style="[
                  userData.data.push_notifications == 0 ? 'opacity: .5;' : '',
                ]"
              >
                <input
                  type="checkbox"
                  v-model="pushNotifications"
                  :id="preference.id"
                  :value="preference.id"
                  :name="preference.id"
                  :selected="pushNotifications.includes(preference.id)"
                  :disabled="userData.data.push_notifications == 0"
                />
                <label :for="preference.id"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ preference.name }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import {
  preferenceValueLabels,
  preferenceCategoryIds,
  preferenceCategoryIdsHelpOnline,
} from "@/services/preference-options";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "PreferenceCategory",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  data() {
    return {
      preferenceValueLabels: preferenceValueLabels,
      preferenceCategoryIds: preferenceCategoryIds,
      preferenceCategoryIdsHelpOnline: preferenceCategoryIdsHelpOnline,
      customPreferenceCategories: [
        preferenceCategoryIds.menu,
        preferenceCategoryIds.pushNotifications,
      ],
      bulkUpdateCategories: [
        preferenceCategoryIds.menu,
        preferenceCategoryIds.pushNotifications,
      ],
      menu: [],
      pushNotifications: [],
      helpSlug: preferenceCategoryIdsHelpOnline[this.$route.params.categoryId],
    };
  },
  computed: {
    ...mapState("preferences", ["preferences", "preferenceUpdateData"]),
    ...mapState("user", ["userData"]),
    routeParam() {
      const { categoryId } = this.$route.params;
      return categoryId;
    },
  },
  methods: {
    ...mapActions("preferences", [
      "getUserPreferences",
      "setPreferenceUpdateData",
    ]),
    getPreferences() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.getUserPreferences(this.$route.params.categoryId)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.getInitialValues();
        });
    },
    setData() {
      // Currently only for menu preference category
      let preferenceUpdateData = {};
      let menuUpdateData = [];
      let pushNotificationsUpdateData = [];
      switch (Number(this.routeParam)) {
        case this.preferenceCategoryIds.menu:
          //update multiple preferences, using update bulk
          this.preferences.forEach((pref) => {
            menuUpdateData.push({
              is_automatic: Object.values(this.menu).includes(pref.id) ? 0 : 1,
              preference: pref.preference,
              values: [
                {
                  value: Object.values(this.menu).includes(pref.id) ? 0 : 1,
                  id: pref.preference_values[0].id,
                },
              ],
            });
          });
          preferenceUpdateData.userpreferences = menuUpdateData;
          this.$store.commit("preferences/setMenuPreferences", null, {
            root: true,
          });
          break;
        case this.preferenceCategoryIds.pushNotifications:
          //update multiple preferences, using update bulk
          this.preferences.forEach((pref) => {
            pushNotificationsUpdateData.push({
              is_automatic: Object.values(this.pushNotifications).includes(
                pref.id
              )
                ? 0
                : 1,
              preference: pref.preference,
              values: [
                {
                  value: Object.values(this.pushNotifications).includes(pref.id)
                    ? 0
                    : 1,
                  id: pref.preference_values[0].id,
                },
              ],
            });
          });
          preferenceUpdateData.userpreferences = pushNotificationsUpdateData;
          break;
        default:
          break;
      }
      this.setPreferenceUpdateData(preferenceUpdateData);
      this.updatePreference();
    },
    updatePreference() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.bulkUpdateCategories.includes(Number(this.routeParam))) {
        this.url = `${apiEndpoints.company.userPreferences}/bulk`;
      } else {
        this.url = `${apiEndpoints.company.userPreferences}/${this.preferences[0].id}`;
      }
      httpServiceAuth
        .put(this.url, this.preferenceUpdateData)
        .then((response) => {
          const preferencesUpdateData = [...this.preferences];
          const objIndex = preferencesUpdateData.findIndex(
            (obj) => obj.id == response.data.data.id
          );
          preferencesUpdateData[objIndex] = response.data.data;
          this.setPreferences(preferencesUpdateData);
          this.$router.push({ name: "r_preference-category" });
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        });
    },
    getInitialValues() {
      if (this.preferences) {
        this.preferences.forEach((pref) => {
          switch (pref.preference_category_id) {
            case this.preferenceCategoryIds.menu:
              if (
                (pref.preference_user_values.length &&
                  pref.preference_user_values[0].value == 0) ||
                !pref.is_automatic
              ) {
                this.menu.push(pref.id);
              }
              break;
            case this.preferenceCategoryIds.pushNotifications:
              if (
                (pref.preference_user_values.length &&
                  pref.preference_user_values[0].value == 0) ||
                !pref.is_automatic
              ) {
                this.pushNotifications.push(pref.id);
              }
              break;
            default:
              break;
          }
        });
      }
    },
  },
  created() {
    this.getPreferences();
  },
  watch: {
    "$route.params.categoryId"(value) {
      if (value) {
        this.getPreferences();
        this.helpSlug =
          preferenceCategoryIdsHelpOnline[this.$route.params.categoryId];
        this.getHelpOnline(this.helpSlug);
      }
    },
  },
};
</script>
